.nav-desktop-header {
  background-color: #6c009e;
  display: flex;
}

.nav-desktop-header * a,
.nav-mobile-header * a {
  font-size: 16px;
}

.ant-menu-submenu:hover,
.ant-menu-item:hover,
.ant-menu-item-active {
  border-bottom: none !important;
  color: none;
}

.nav-mobile-header {
  display: block;
  height: auto;
}

.nav-mobile-header-main-menu {
  flex: 5;
  text-align: center;
  border: none;
}

.mobile-menu-toggle {
  cursor: pointer;
}

.nav-footer-logo {
  margin-bottom: 20px;
}

.nav-footer * a {
  color: white !important;
}

.nav-footer-menu {
  text-align: left;
}

.nav-footer-menu * a {
  font-size: 16px;
  color: white !important;
}

.nav-footer-menu * .ant-row {
  margin-bottom: 10px !important;
}
