#root {
  min-height: 100vh;
}

.ant-layout-content {
  min-height: calc(100vh - 328px);
  height: auto !important;
}

h3.nudge {
  font-weight: bold;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 2.5px;
}

h4 {
  font: normal normal bold 16px/25px ;
}

p {
  font: normal normal normal 16px/25px ;
}

.btn {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1.2px;
}


.min-h-100vh {
  min-height: 100vh;
}
.rnm-input,
.rnm-select {
  border-radius: 0.5rem;
  border: 1px solid #b8bcc3;
  margin-top: 4px;
}
.rnm-input-error {
  color: #c00;
  padding: 4px;
}
.ant-checkbox-input {
  height: 17px;
  width: 17px;
}
.ant-checkbox-inner {
  border-color: #6c009e;
  border-width: 1px;
  border-radius: 4px;
  height: 19px;
  width: 19px;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #6c009e;
}
.ant-select-selector,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.rnm-input .irvESH {
  border-radius: 0.5rem;
  border: none;
}
.rnm-placeholder-credit-card,
.rnm-placeholder-credit-card > .irvESH {
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
}
.rnm-select {
  font-size: 16px;
}
.rnm-select .ant-select-selector {
  height: 40px !important;
}
.rnm-select .ant-select-selector .ant-select-selection-search-input {
  height: 40px !important;
}
.rnm-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector::after,
.rnm-select.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 40px;
}
.rnm-select.small {
  font-size: 12px !important;
}
.rnm-select.small .ant-select-selector,
.rnm-select.small .ant-select-selector .ant-select-selection-search-input {
  height: 32px !important;
}
.rnm-select.small.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector::after,
.rnm-select.small.ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 32px;
}
@media screen and (max-width: 960px) {
  .list-check {
    height: 30px;
    width: 30px;
  }
  .ant-steps-item-content .ant-steps-item-title {
    letter-spacing: -0.05em;
  }
}
.app-sleep-hero {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  background: linear-gradient(
    269.9deg,
    #e2e2e1 1.01%,
    #e4e6e4 2.36%,
    #e7e9e9 4.59%,
    #eaebec 6.24%,
    #edeeef 9.51%,
    #eeeff0 13.75%,
    #eff0f1 17.42%,
    #f1f2f3 26.15%,
    #f0f1f3 31.65%,
    #eff0f2 38.69%,
    #edeeef 48.9%,
    #edeeef 61.11%,
    #edeeef 73.76%,
    #ecedee 96.23%
  );
}
.about-us-hero {
  /* background-image: url('./assets/images/image-about@3x.png'); */
  background-size: cover;
  text-align: center;
}

.about-us-hero > h2 {
  color: black;
}

.about-us-easy-and-quick {
  background-color: #eee2f6;
}

.about-us-easy-and-quick > h3 {
  text-align: center;
}

.about-us-hassle-free {
  background-color: #38006b;
}

.about-us-hassle-free > h3 {
  text-align: center;
}

.about-us-hassle-free > h3,
.about-us-hassle-free > p {
  color: white;
}

.about-us-evaluation {
  /* background-image: url('./assets/images/image-evaluation@3x.png'); */
  background-size: cover;
  text-align: center;
}

.about-us-evaluation > p {
  text-align: left;
}
.section-pt-0 {
  padding-top: 0 !important;
}
.section-pb-0 {
  padding-bottom: 0 !important;
}
.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  height: 1px;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #6c009e;
}
.ant-steps-item-content {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}
.ant-steps-item-title {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.sv_qstn .sq-root {
  /* border: 1px solid red;
  border-left: 4px solid #18a689;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 30px; */
}
.sv_qstn .sq-root-cb {
  /* border-left: 4px solid orange; */
}
.sq-title {
  font-size: 20px;
  margin-left: 20px;
}
.sq-title-required {
  /* color: red; */
}
.sq-label {
  margin-left: 20px;
}
.sq-item:nth-child(1) {
  margin-bottom: 5px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .anticon-plus-circle,
.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .anticon-minus-circle {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 16px;
  display: inline-block;
  font-size: 12px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.sv_q.sv_qstn a {
  color: #6c009e;
}

.ant-picker-cell-disabled::before {
  background-color: transparent;
}
.btn-renuma-calendar:hover,
.btn-renuma-calendar:active,
.btn-renuma-calendar:visited,
.btn-renuma-calendar:focus {
  border-color: #9751b7;
}
.btn-renuma-calendar:hover {
  color: #9751b7;
}
.border-l-6 {
  border-left-width: 0.375rem;
}
.border-r-6 {
  border-right-width: 0.375rem;
}
.header-double-price {
  height: 73px;
}
.list-small-icon .ant-avatar {
  width: 20px;
  height: 20px;
}
.text-2xs {
  font-size: 0.625rem;
  line-height: 0.75rem;
}
.product-selector {
}
.product-selector .ant-select-selector {
  height: 63px !important;
}
@media screen and (max-width: 767.98px) {
  .list-small-icon .ant-avatar {
    width: 10px;
    height: 10px;
  }
}
.ant-layout-sider.rnm-sider,
.ant-layout-sider.rnm-sider .ant-menu {
  background-color: #470068;
}
.ant-layout-sider.rnm-sider .ant-menu .ant-menu-item .ant-menu-item-icon svg,
.ant-layout-sider.rnm-sider .ant-menu .ant-menu-item .ant-menu-title-content {
  color: #ffffff;
}
.ant-layout-sider.rnm-sider
  .ant-menu
  .ant-menu-item.ant-menu-item-selected
  .ant-menu-item-icon
  svg,
.ant-layout-sider.rnm-sider
  .ant-menu
  .ant-menu-item.ant-menu-item-selected
  .ant-menu-title-content {
  color: #6c009e;
}
.ant-layout-sider.rnm-sider .ant-menu .ant-menu-item.ant-menu-item-selected {
  background-color: #ffffff;
  border-left: 7px solid #9751b7;
}
.ant-layout-sider.rnm-sider .ant-layout-sider-trigger {
  background-color: #240035;
}
.card-dashboard .ant-card-head {
  border-bottom: none;
}
.card-dashboard .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
  font-size: 1rem;
  color: #3b3c41;
}
.card-dashboard .ant-card-head .ant-card-head-wrapper .ant-card-extra a {
  font-size: 1rem;
  color: #6c009e;
}
.card-dashboard .ant-card-actions {
  border-top: none;
}

.card-form .ant-card-body {
  padding: 0;
}

.card-dashboard.card-body-1 .ant-card-body {
  padding-bottom: 0;
  padding-top: 0;
}
.card-actions-right {
  flex-direction: column;
  display: flex;
}
.card-actions-right .ant-card-body {
  flex: 1 1 auto;
  padding-top: 0.5rem;
}
.card-actions-right .ant-card-actions li {
  margin-top: 0;
}
.card-actions-right .ant-card-actions li span {
  text-align: right;
  padding: 0 24px 12px 24px;
}
.card-actions-right .ant-card-actions li span img {
  display: inline-block;
}
.ant-layout-sider-zero-width-trigger {
  background-color: #6c009e;
}
.sv_qstn {
  min-width: 100% !important;
}
div[name="p11q6"] div[data-value]:after,
div[name="question1"] div[data-value]:after {
  content: "%";
}
.noUi-horizontal .noUi-origin {
  height: auto;
  width: 10%;
}
.ytp-watermark.yt-uix-sessionlink {
  display: none !important;
}
.sv_main.sv_default_css .sv_custom_header {
  background-color: transparent !important;
}
.disable-layer {
  position: relative;
}
.disable-layer::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.84);
  z-index: 9999;
}
h4.sv_page_title {
  margin-bottom: 24px;
}
h4.sv_page_title .sv-string-viewer {
  font-weight: 700;
  font-size: 120%;
  color: rgb(151, 81, 183);
}
.cpap-machine {
  transition: all 0.5s;
}
.cpap-machine-description p,
.mask-description p,
.watchpat-one p {
  line-height: 1.5 !important;
  font-size: 1rem !important;
  font-family: Helvetica !important;
}
.mask-description-sm p,
.mask-description-sm li {
  line-height: 1.25 !important;
  font-size: 0.75rem !important;
  font-family: Helvetica !important;
}
.mask-list p {
  margin-bottom: 0 !important;
}
.renumaplus-select.ant-select-single.ant-select-lg:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  height: 32px;
}
.renumaplus-select.ant-select-single.ant-select-lg:not(
    .ant-select-customize-input
  ):not(.ant-select-customize-input)
  .ant-select-selection-search-input {
  height: 30px;
}
.renumaplus-select.ant-select-single.ant-select-lg:not(
    .ant-select-customize-input
  )
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 30px;
}
.btn-sort .anticon.anticon-caret-down,
.btn-sort .anticon.anticon-caret-up {
  vertical-align: middle !important;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.js-image-zoom__zoomed-area {
  border: 1px solid rgba(151, 81, 183, 1);
}
.js-image-zoom__zoomed-image {
  border-radius: 7px;
  z-index: 99;
  border: 1px solid rgba(151, 81, 183, 1);
  background-color: #ffffff;
}
.react-datepicker__navigation--years-upcoming:after {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 11px;
  left: 12px;
  width: 9px;
  transform: rotate(-45deg);
}
.react-datepicker__navigation--years-previous:after {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 11px;
  left: 12px;
  width: 9px;
  transform: rotate(135deg);
}
.react-datepicker__navigation-icon::before {
  top: 12px !important;
}
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
  top: 4px !important;
}
.react-datepicker__month-dropdown-container,
.react-datepicker__year-dropdown-container {
  padding-right: 21px;
}
.collapse-register .ant-collapse-header {
  padding-right: 12px !important;
}

.bg-nudge {
  background-size: 1470px;
  background-position: -50% bottom;
}

.bg-nudge .text-container {
  max-width: 550px;
  padding-right: 100px;
}