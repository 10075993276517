
  
  .landing-page-hero {
    background-image: linear-gradient(
        to right top,
        #f8f5ff,
        rgba(255, 255, 255, 0.15)
      ),
      url('https://images.unsplash.com/photo-1519600031026-82f2950cf220?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2255&q=80');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  